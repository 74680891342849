import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';

export default styled.div`
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 1em;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-rows: 1fr 1em;
    -ms-grid-columns: 1fr 1em 1fr 1em 1fr;

    .grid-item:nth-child(1) {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    .grid-item:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .grid-item:nth-child(3) {
      -ms-grid-row: 1;
      -ms-grid-column: 5;
    }
  }

  .post-text {
    display: none;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid ${colors.secondaryGray};
    margin: 1em auto;
    .bar {
      display: inline;
    }
    .postDate {
      display: inline;
    }
    .image-box {
      background-color: ${colors.white};
      width: 100%;
    }
    .image-box img {
      position: relative;
      float: left;
      width: 100%;
      overflow: hidden;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 0;
    }
    .post-category {
      margin-bottom: 0;
    }
    .post-details {
      flex-grow: 1;
      padding: 1em;
      background-color: ${colors.white};
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      border-radius: 5px;
    }

    .post-header, .post-excerpt p {
      margin-bottom: 1em;
    }

    .post-excerpt {
      flex: 1 1 auto;
    }
  }
`;
