import React, { Component } from 'react';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';
import format from 'date-fns/format';

// COMPONENTS
import NavBar from '../NavBar';
import EmailSignupForm from '../EmailSignupForm';
import CircleSectionVideos from '../CircleSectionVideos';
import ServicesContainer from '../ServicesContainer';
import RelatedBlogPosts from './RelatedBlogPosts';
import ShareWidget from '../ShareWidget';
import SEO from '../SEO';

const StyledVideoPage = styled.div`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  img {
    height: auto;
  }
  .title {
    font-size: 48px;
    margin-bottom: 1.45em;
    text-transform: none;
  }
  .circle-container {
    display: flex;
    flex-direction: column;
    margin: 3em auto 0;
    max-width: 1150px;
  }
  .black-circle,
  .gray-circle {
    border-radius: 50%;
    width: 540px;
    height: 540px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3em;
    align-self: center;
  }
  .gray-circle {
    background-color: ${colors.secondaryGray};
    margin-top: -4em;
    margin-bottom: 4em;
    svg {
      color: black;
      height: 45px;
      width: 45px;
    }
  }
  .black-circle {
    background-color: black;
    h2 {
      color: white;
    }
  }

  @media (min-width: 500px) {
    .black-circle {
      margin-left: auto;
      padding: 0;
    }
    .gray-circle {
      margin-right: auto;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .title {
      max-width: 90%;
    }
  }
`;

class VideoPost extends Component {
  render() {
    const props = this.props;
    const post = props.post;
    const tags = props.tags;
    const allServices = props.allServices;

    var tagTopics = [];
    for (var key in tags.edges) {
      for (var topicKey in post.topic) {
        if (post.topic[topicKey] === tags.edges[key].node.wordpress_id) {
          tagTopics.push(tags.edges[key].node.name);
        }
      }
    }

    //create array of all tags
    var taglabels = [];
    for (var key in tags.edges) {
      taglabels[tags.edges[key].node.wordpress_id] = tags.edges[key].node.name;
    }

    var tagServices = [];
    for (var key in allServices.edges) {
      for (var topicKey in post.our_solutions) {
        if (
          post.our_solutions[topicKey] ===
          allServices.edges[key].node.wordpress_id
        ) {
          var thisLink =
            "<a href='/solutions/" +
            allServices.edges[key].node.slug +
            "'>" +
            allServices.edges[key].node.name +
            '</a>';
          tagServices.push(thisLink);
        }
      }
    }
    //create array of all services
    var servicelabels = [];
    for (var key in allServices.edges) {
      servicelabels[allServices.edges[key].node.wordpress_id] =
        allServices.edges[key].node.name;
    }

    const formattedDate = format(new Date(post.date), 'MMMM D, YYYY');

    return (
      <StyledVideoPage className="animate">
        {!this.props.isPreviewMode && (
          <SEO
            isBlogPost={true}
            postData={post}
            postImage={post.better_featured_image.source_url}
          />
        )}

        <NavBar link="/insights/videos" name="Videos" />
        <h1 className="page-container title">{post.title}</h1>

        <CircleSectionVideos
          date={formattedDate}
          background={post.better_featured_image.source_url}
          postCopy={post.content}
          video={post.acf.video}
          gated={post.acf.gated_video}
          contact_banner={post.acf}
          topics={tagTopics}
          tagId={post.topic}
          services={tagServices}
          button={"WATCH NOW"}
        />

      </StyledVideoPage>
    );
  }
}

export default VideoPost;
