import React, { Component } from "react";
//FORMIK SPECIFIK
import { Helmet } from "react-helmet";
// import 'whatwg-fetch';
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CountryDropdown } from "react-country-region-selector";
//STYLE
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";

const StyledFormik = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 100%;
  }

  select option[selected] {
    background-color: ${colors.black};
  }
  /* select label is used outside of form-group to handle the toggling of the arrow */
  .selectLabel {
    color: ${colors.black};
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
    input {
      border: 1px solid ${colors.primaryGray};
      padding: 0.6rem;
      border-radius: 4px;
    }
    label {
      color: ${colors.black};
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .phone {
    /* this class is used to check the form for spam bots */
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
  select {
    background: white;
    border: 1px solid #757575;
    padding: 0.6rem;
    border-radius: 4px;
    display: inline-block;
    line-height: 1.5em;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, black 50%),
      linear-gradient(135deg, black 50%, transparent 50%),
      linear-gradient(to right, white, white);
    background-position: calc(100% - 20px) 19px, calc(100% - 15px) 19px, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.65em;
    background-repeat: no-repeat;
  }

  select:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, white, white);
      background-position: calc(100% - 20px) 13px, calc(100% - 15px) 13px, 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.65em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 ${colors.black};
  }

  .button-align {
    text-align: center;
    width: 100%;
  }
  #message {
    width: 100%;
    textarea {
      border: 1px solid ${colors.primaryGray};
      height: 200px;
      max-height: 200px;
      border-radius: 4px;
      overflow-y: auto;
    }
  }
  .success-message {
    margin-bottom: 4rem;
    display: none;
  }

  .field-error {
    font-size: 0.8rem;
    color: ${colors.primary};
  }

  .download, .watch-video {
    font-weight: bold;
    margin-top: 30px;
    font-family: ${fonts.subHead};
  }

  .submit-btn {
    background-color: ${colors.primary};
    border: 0;
    font-size: 14px;
    font-family: ${fonts.subHead};
    color: ${colors.white};
    border-radius: 25px;
    padding: 13px 25px;
    border: 1px solid ${colors.primary};
    letter-spacing: 3px;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    a {
      color: white;
    }
  }
  .submit-btn:hover {
    background-color: ${colors.secondaryRed};
    transition: 0.3s all ease;
  }

  .styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    // Box.
    & + label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      border: 2px solid ${colors.blue};
      background: white;
    }

    // Box hover
    &:hover + label:before {
      background: ${colors.blue};
    }

    // Box checked
    &:checked + label:before {
      background: ${colors.blue};
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 12px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
`;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: '',
  title: "",
  company: "",
  country: "United States",
  checkBox: true
};

const Fieldset = ({ name, label, children, classname, id, ...rest }) => (
  <div className={classname} id={id}>
    <label htmlFor={name}>{label}  <ErrorMessage name={name} component="span" className="field-error" /></label>
    <Field id={name} name={name} {...rest}>
      {children}
    </Field>
  </div>
);

class FormikForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      successMsg: "",
      border: "form-container"
    };
  }

  spamCheck = (values, actions) => {
    // Reserving this area to inlude more spam checking rules
    if (values.phone !== '') {
      // if values.phone is filled out, treat this as spam. values.phone should not be accessable by a user

    } else {
      // if values.phone is empty, submit this form
      this.handleSubmit(values, actions);
    }
  }

  handleSubmit = (values, actions) => {
    const dataObject = {
      context: {
        pageUri: window.location.href,
        pageName: "Videos | Ansira"
      },
      fields: [
        {
          name: "firstname",
          value: values.firstName
        },
        {
          name: "lastname",
          value: values.lastName
        },
        {
          name: "email",
          value: values.email
        },
        {
          name: "jobtitle",
          value: values.title
        },
        {
          name: "company",
          value: values.company
        },
        {
          name: "country",
          value: values.country
        },
        {
          name: "sign_up_for_newsletter",
          value: values.checkBox
        }
      ]
    };
    fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/3224127/d5a9ea08-808c-4113-b193-e1d7faf91781",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataObject)
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data) {
          this.setState({
            success: true,
            successMsg: "",
            downloadTitle: "",
            border: ""
          });
          this.displayVideo();
        }
      });

    actions.setSubmitting(false);
    actions.resetForm(initialValues);

  }

  displayVideo = () => {
    let iframe = document.querySelector("iframe");
    iframe.src = this.props.n.src;
  }


  render() {
    if (!this.props.gated) {
      return false;
    } else return (
      <StyledFormik>
          <Formik
          className={this.state.border}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .trim()
                .required("*Please complete this required field."),
              lastName: Yup.string()
                .trim()
                .required("*Please complete this required field."),
              email: Yup.string()
                .trim()
                .email("*Please complete this required field.")
                .required("*Please complete this required field."),
              title: Yup.string()
                .trim()
                .required("*Please complete this required field."),
              company: Yup.string()
                .trim()
                .required("*Please complete this required field.")
            })}
            onSubmit={(values, actions, message) => {
              if (values.phone !== '') {
                // if values.phone is filled out, treat this as spam. vales.phone should not be accessable by a user
              } else {
                // if values.phone is empty, submit this form
                this.spamCheck(values, actions);
              }
            }}
          >
            {({ values, actions, handleChange, handleBlur }) => (
              <div>
                <Form
                  style={{ display: this.state.success ? "none" : "block" }}
                >
                    <Fieldset
                      classname="form-group names"
                      name="firstName"
                      label="First Name"
                      type="text"
                      placeholder=""
                    />
                    <Fieldset
                      classname="form-group names"
                      name="lastName"
                      label="Last Name"
                      type="text"
                      placeholder=""
                    />
                    <Fieldset
                      classname="form-group emailphone"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder=""
                    />
                
                    <Fieldset
                      classname="form-group emailphone"
                      name="title"
                      label="Job Title"
                      type="text"
                      placeholder=""
                    />
                    <Fieldset
                      classname="form-group emailphone"
                      name="company"
                      label="Company"
                      type="text"
                    />
                    <div className="form-group emailphone">
                      <label htmlFor="country">Country</label>
                      <CountryDropdown
                        name="country"
                        value={values.country}
                        onChange={(_, e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </div>
                  <span>
                    <input
                      className="styled-checkbox"
                      type="checkbox"
                      name="checkBox"
                      id="box"
                      checked={values.checkBox}
                      value={values.checkBox}
                      onChange={handleChange}
                    />
                    <label for="box">
                      Yes, I'd like to receive marketing communications from
                      Ansira.
                    </label>
                  </span>
                  <div className="watch-video">
                    <button type="submit" className="submit-btn">{this.props.download}</button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        <p
          className="success-message"
          style={{ display: this.state.success ? "block" : "none" }}
        >
          {this.state.successMsg}
        </p>
      </StyledFormik>
      
    );
  }
}
export default FormikForm;
