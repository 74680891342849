import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import { breakpoints, fonts, colors } from "../../style-utilities/variables";
import ShareWidget from "../../components/ShareWidget";
import ServicesContainer from "../../components/ServicesContainer";

const StyledDetailsSidebar = styled.div`
  margin-left: auto;

  .author {
    a {
      font-size: 22px;
    }
    h4 {
      color: ${colors.primaryGray};
      font-size: 20px;
      margin-top: 0.5em;
    }
    .author-image {
      max-width: 180px;
      margin-bottom: 2em;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .bio {
      display: none;
    }
  }

  .subDivs {
    padding-bottom: 0.7rem;
    h3 {
      margin-bottom: 0.6rem;
      font-size: 22px;
    }
    ul {
      margin-bottom: 0;
      margin-left: 0;
    }
    p,
    li {
      font-size: 16px;
      list-style: none;
      color: ${colors.primaryGray};
      margin-bottom: 0;
    }
    a {
      color: ${colors.primary};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .author {
      .author-image {
        margin-bottom: 0;
      }
      .bio {
        display: inline-block;
      }
    }
    .right-subDivs,
    .left-subDivs {
      width: 100%;
    }
    .left-subDivs {
      padding-right: 0;
    }
  }
`;

const VideoSideBar = props => {

  var renderServices = "";

  for (var key in props.services) {
    renderServices += props.services[key] + "<br>";
  }

  return (
    <StyledDetailsSidebar>
      <div className="left-subDivs">
        <div className="subDivs bottom-line">
          <h3>Date</h3>
          <p>{props.date}</p>
        </div>
      </div>
      <div className="right-subDivs">
        <div className="topic subDivs bottom-line">
          <h3>Topics</h3>
          {props.topics.map((topic, index) => (
            <p key={index} className="tags">
              <Link to={`/insights/videos/?=${props.tagId[index]}`}>{ReactHtmlParser(topic)}</Link>
            </p>
          ))}
        </div>
        <div className="services subDivs bottom-line">
          <h3>Solutions</h3>
          {ReactHtmlParser(renderServices)}
        </div>
        <div className="share subDivs">
          <h3>Share</h3>
          <ShareWidget/>
        </div>
      </div>

    </StyledDetailsSidebar>
  );
};

export default VideoSideBar;
