import React, { Component } from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import ReactHTMLParser from "react-html-parser";
import VideoPost from '../components/Videos/VideoPost';
import { breakpoints, fonts, colors } from '../style-utilities/variables';
import styled, { consolidateStreamedStyles } from 'styled-components';
import SEO from '../components/SEO';


// THE VIEW
const StyledVideoPost = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  h1 {
    font-size: 48px;
    margin-bottom: 40px;
  }
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  .title {
    font-size: 48px;
    text-transform: none;
  }

  @media (min-width: ${breakpoints.desktop}) {
    .title {
      max-width: 90%;
    }
  }

`;

class VideoPostTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  post = this.props.data.wordpressWpVideos;
  tags = this.props.data.allWordpressWpTopic;
  allServices = this.props.data.allWordpressWpOurSolutions;
  servicesMap = this.props.data.allWordpressWpOurSolutions.edges.node;

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/posts/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    const previewData = this.state.previewPayload;

    if (this.state.isPreviewMode) {
      this.post.title = previewData.title.rendered;
      this.post.content = previewData.content.rendered;
    }

    return (
      <StyledVideoPost>
        <SEO
          isBlogPost={true}
          postData={this.post}
          postImage={this.post.better_featured_image.source_url}
        />
        <VideoPost
          post={this.post}
          tags={this.tags}
          servicesMap={this.servicesMap}
          allServices={this.allServices} 
        />
      </StyledVideoPost>

    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpVideos(slug: {eq: $slug}) {
      title
      content
      slug
      topic
      our_solutions
      date
      acf {
        seo_canonical
        seo_description
        seo_title
        display_date
        gated_video
        video
      }
      better_featured_image {
         source_url
       }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          id
          name
          slug
          description
          wordpress_id
        }
      }
    }
  }
`;

export default VideoPostTemplate;
