import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import VideoSideBar from "../components/Videos/VideoSideBar";
import VideoFormikForm from "../components/Videos/VideoFormikForm";
import ContactBanner from '../components/ContactBanner';


const StyledHero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  .page-container{
    iframe{
      border:solid black 1px;
    }
  }
  .bottom-line {
    border-bottom: 2px solid ${colors.lightGray};
    margin-bottom: 1.45em;
  }
  .challenge-copy {
    padding-right: 1em;
    img {
      height: auto;
    }
  }

  .width-full {
    width:100% !important;
  }

  .right-container {
    position: relative;
    display: flex;
    flex-direction: column;
    iframe {
      max-width: 100%;
    }
    .video-container {
      margin: 2em auto;
      text-align: center;
    }
    .circle-container {
      width: 450px;
      margin: 2em 0 3em;
      align-self: center;
      padding-top: 450px;
      border-radius: 50%;
      right: calc(50% - 217px);
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      object-fit: cover;
      @media (min-width: 400px) {
        left: auto;
        margin: 2em auto;
      }
    }
    .subHead {
      font-size: 22px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    justify-content: flex-end;
    flex-direction: row;
    .right-container {
      width: 70%;
      padding-top: 0;
      padding-left: calc(2.5rem + 40px);
      .circle-container {
        width: 100%;
        padding-top: 100%;
        right: -50px;
        position: absolute;
      }
      .left-form {
        max-width: 28%;
      }
      .right-content {
        display: flex;
      }
      .challenge-copy {
        padding: 112% 2em 4em 0;
        margin-left: 50px;
      }
      .video-container {
        margin: 2em 0 2em 2em;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .left-container {
      width: 20%;
    }
    .right-container {
      width: 70%;
      padding-left: calc(4.5rem + 40px);
      .video-container {
        margin: 2em 0 2em 4em;
      }
    }
    .challenge-copy {
      max-width: 80%;
    }
    
  }
`;
const HeroVideo = ({
  caseStudy,
  blogPost,
  whitePaperPost,
  logo,
  download,
  client,
  date,
  photo,
  authorName,
  authorLink,
  title,
  bio,
  background,
  underCircle,
  postCopy,
  topics,
  tagId,
  verticals,
  services,
  technology,
  video,
  gated,
  contact_banner,
  button
}) => {
  const heroContainBG = {
    backgroundImage: "url(" + background + ")"
  };

  let n = ReactHtmlParser(video)[0].props;

  let o = ReactHtmlParser(video)[0],
        props = o.props,
        v = {
          src: `${n.src}`,
          ele: `${o.type}`,
          title: `${props.title}`,
          gatedSrc: `${props.src}&controls=false`,
          width: `${props.width}`,
          height: `${props.height}`,
          frameBorder: `${props.frameBorder}`,
          allow: `${props.allow}`,
          allowFullScreen: `${props.allowFullScreen}`
        };

   !gated ? null : video = `<${v.ele} title="${v.title}" src="${v.gatedSrc}" width="${v.width}" height="${v.height}" frameBorder="${v.frameBorder}" allow="${v.allow}" allowFullscreen="${v.allowFullScreen}"></${v.ele}>`

  return (
    <StyledHero className="page-container content-container">
          <div className="left-container">
            <VideoSideBar
              date={date}
              topics={topics}
              tagId={tagId}
              services={services}
            />
          </div>          
          <div className="right-container">
            <div className="right-content">
              <div className="left-form"><VideoFormikForm gated={gated} n={n} download={button} /></div>
              <div className="video-container">{ReactHtmlParser(video)}</div>
            </div>
            <div>{ReactHtmlParser(postCopy)}</div>
            <ContactBanner
              headline={contact_banner.contact_headline}
              cta={contact_banner.contact_cta}
              url={contact_banner.contact_url}
            />
          </div>
    </StyledHero>
  );
};

export default HeroVideo;
