import React, { Component } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import BlogPostGridItem from "./BlogPostGridItem";
import StyledGrid from "./BlogGrid3.styles";
import styled from "styled-components";

const Div = styled.div`
  margin-bottom: 4em;
  height: 100%;
  h3 {
    font-size: 40px;
    margin-top: 1.5em;
  }
`;

function randID() {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36);
  var id = number.toString(36).substr(2, 9);
  return id;
}

const RelatedBlogsComponent = ({
  data,
  title,
  slug,
  topics,
  labels,
  ...remProps
}) => {
  const tagLabel = labels;

  return (
    <div {...remProps}>
      <h3 style={{ textAlign: "center" }}>{title}</h3>
      <StyledGrid>
        {data.slice(0, 3).map(({ node }) => {
          const tagNames = node.topic;
          return (
            <BlogPostGridItem
              key={node.slug}
              item={node}
              className="grid-item"
            />
          );
        })}
      </StyledGrid>
    </div>
  );
};

const RelatedBlogPosts = ({ data, title, slug, topics, labels }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost {
          edges {
            node {
              slug
              title
              topic
              type
              content
              date(formatString: "MMMM D, YYYY")
              author {
                name
                slug
              }
              acf {
                excerpt
                primary_topic
              }
              better_featured_image {
                source_url
                description
                caption
              }
            }
          }
        }
      }
    `}
    render={data => {
      const featuredData = data.allWordpressPost.edges;
      const postTopics = topics;

      //Iterate over current topics and all other blog topics and find matching candidates
      function testTopicsArrays(currentTopics, searchTopics) {
        var relatedTopics = [];
        var topicCount = 0;
        searchTopics.forEach(function(otherBlogTopic, x) {
          currentTopics.forEach(function(currentBlogTopic, y) {
            if (currentBlogTopic === otherBlogTopic) {
              topicCount++;
              searchTopics.matchCount = topicCount;
              relatedTopics.push(searchTopics);
            }
          });
        });
        return relatedTopics;
      }

      //find posts with matching content
      let filteredFeatured = featuredData.filter(cs =>
        testTopicsArrays(postTopics, cs.node.topic)
      );

      //remove the current post from featured posts
      filteredFeatured = filteredFeatured.filter(function(e) {
        return e.node.slug !== slug;
      });

      //sort by posts with the most related topics first
      filteredFeatured.sort((a, b) => {
        const aMatchCount =
          typeof a.node.topic.matchCount === "undefined"
            ? 0
            : a.node.topic.matchCount;
        const bMatchCount =
          typeof b.node.topic.matchCount === "undefined"
            ? 0
            : b.node.topic.matchCount;

        return bMatchCount - aMatchCount;
      });

      return (
        <Div>
          <RelatedBlogsComponent
            title={title}
            data={filteredFeatured}
            labels={labels}
          />
        </Div>
      );
    }}
  />
);
export default RelatedBlogPosts;
